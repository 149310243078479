import React from 'react'
import { ButtonContainer } from './style'
export default function Buttons({ type, label }) {
    return (
        <ButtonContainer>
            <button type={ type }>
                { label }
            </button>
        </ButtonContainer>
    )
}
