import React from 'react'
import { Add } from './style'
export default function AddCar({ labelText, type, dataId, inputID, checked, labelStyle, multiple, inputHtmlFor, onChange, maxLength, onFocus, onBlur, value, name, placeholder, hidden, ref }) {
        return (
            <Add>
                <div className="group">
                    <input id={inputID} required hidden={hidden} data-id={dataId} checked={checked} multiple={multiple} maxLength={maxLength} name={name} type={type} value={value} placeholder={placeholder} onFocus={onFocus} onBlur={onBlur} ref={ref} onChange={onChange}/>
                    <span className="highlight"></span>
                    <span className="bar"></span>
                    <label style={labelStyle} htmlFor={inputHtmlFor}>{labelText}</label>
                </div>
            </Add>
        )
}
