import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { development } from "../../config";
import * as actions from "../../Actions";

class BonusAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bonusName: "",
      bonusPrice: "",
      bonusPricePercentage: "",
      percentageDirection: "",
    };
  }

  submitForm() {
    console.log(this.state.bonusPrice);
    console.log(this.state.bonusPricePercentage);
    if (!this.state.bonusName) alert("Prim adı gereklidir.");
    else if (!this.state.bonusPrice && !this.state.bonusPricePercentage)
      alert("Prim tutarlarından biri girilmelidir.");
    else if (this.state.bonusPrice && this.state.bonusPricePercentage)
      alert("Prim tutarlarından sadece bir tanesini girebilirsiniz.");
    else if (this.state.bonusPricePercentage && !this.state.percentageDirection)
      alert("Prim baz alışı seçiniz.");
    else {
      var bonus = {
        bonusName: this.state.bonusName,
        bonusPrice: this.state.bonusPrice,
        bonusPricePercentage: this.state.bonusPricePercentage,
        percentageDirection: this.state.percentageDirection,
      };
      let url = `${development}/api/bonus`;
      axios.post(url, { bonus: bonus }).then((res) => {
        alert("Prim başarıyla kaydedildi.");
        window.location.href = "/prim-listesi";
      });
    }
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  render() {
    const { bonusName, bonusPrice, bonusPricePercentage } = this.state;
    return (
      <div style={{ textAlign: "center" }}>
        <hr />
        <p style={{ padding: 15, margin: 15, fontWeight: "bolder" }}>
          TL veya % alanını doldurun. İkisini aynı anda doldurmayın!
        </p>
        <hr />
        <div style={{ width: "100%", padding: 15, margin: 15 }}>
          <label style={{ fontSize: 21 }}>Prim Adı</label>
          <br />
          <input
            type="text"
            name="bonusName"
            value={bonusName}
            onChange={this.handleChange}
            placeholder="Takas Primi"
            style={{
              fontSize: 16,
              width: "25%",
              border: "1px gray solid",
              margin: 15,
              padding: 15,
              borderRadius: 5,
            }}
          />
        </div>
        <div style={{ width: "100%", padding: 15, margin: 15 }}>
          <label style={{ fontSize: 21 }}>Prim Tutarı (TL)</label>
          <br />
          <input
            type="text"
            name="bonusPrice"
            value={bonusPrice}
            onChange={this.handleChange}
            placeholder="2500"
            style={{
              fontSize: 16,
              width: "25%",
              border: "1px gray solid",
              margin: 15,
              padding: 15,
              borderRadius: 5,
            }}
          />
        </div>
        <div style={{ width: "100%", padding: 15, margin: 15 }}>
          <label style={{ fontSize: 21 }}>Prim Tutarı (%)</label>
          <br />
          <input
            type="text"
            name="bonusPricePercentage"
            value={bonusPricePercentage}
            onChange={this.handleChange}
            placeholder="5"
            style={{
              fontSize: 16,
              width: "25%",
              border: "1px gray solid",
              margin: 15,
              padding: 15,
              borderRadius: 5,
            }}
          />
        </div>
        <div style={{ width: "100%", padding: 15, margin: 15 }}>
          <label style={{ fontSize: 21 }}>Hangi Tutar Baz Alınacak?</label>
          <br />
          <select
            name="percentageDirection"
            value={this.state.percentageDirection}
            onChange={this.handleChange}
            style={{
              fontSize: 16,
              width: "25%",
              border: "1px gray solid",
              margin: 15,
              padding: 15,
              borderRadius: 5,
            }}
          >
            <option value="" disabled selected>
              Seçiniz
            </option>
            <option value="Purchase_Price">Araç Alış Fiyatı</option>
            <option value="Sell_Price">Araç Satış Fiyatı</option>
            <option value="Discount_Price">Araç İndirim Oranı</option>
          </select>
        </div>
        <div style={{ width: "100%", padding: 15, margin: 15 }}>
          <button
            onClick={() => this.submitForm()}
            style={{ padding: 5, margin: 15, width: 150, fontSize: 16 }}
          >
            Kaydet
          </button>
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return state;
}, actions)(BonusAdd);
