import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getCredit} from "../../Actions";
import {CreditViewComponent} from './style'
class CreditView extends Component {

    componentDidMount() {
        this.props.getCredit()
    }

    render() {
        const { getCredit } = this.props.borzMotor;
        return (
            <CreditViewComponent>
                {
                    getCredit && getCredit.length > 0 && getCredit[0] && getCredit[0] &&
                    <React.Fragment>
                        <div>
                            <span>Maksimum Tutar</span>
                            <span>Minimum Tutar</span>
                            <span>Kredi Oranı</span>
                            <span>Kredi Vadesi</span>
                        </div>
                        <div>
                            <span>{getCredit[0].maxValue}</span>
                            <span>{getCredit[0].minValue}</span>
                            <span>{getCredit[0].range}</span>
                            <span>{getCredit[0].yearValue}</span>
                        </div>
                    </React.Fragment>
                }
            </CreditViewComponent>
        );
    }
}

export default connect((state) => { return state }, { getCredit })(CreditView)