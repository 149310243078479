import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { WhoTookViewContainer } from "./style";

import ModelViewComponent from "../../Components/ModelView/Loadable";
import Input from "../../Components/FormElements/Input/Loadable";

import { connect } from "react-redux";
let actions = require("../../Actions");

class WhoTookView extends Component {
  state = {
    whoTookNameState: [],
    whoTookListState: [],
  };
  componentDidMount() {
    this.props.whoTookView();
  }

  filterBrands(e) {
    const { whoTookView } = this.props.borzMotor;
    const findTo = e.target.value;
    findTo.length === 0 && this.setState({ whoTookNameState: [] });
    whoTookView &&
      whoTookView.filter(
        (emp) =>
          emp.whoTook === findTo && this.setState({ whoTookNameState: [emp] })
      );
  }

  render() {
    const { whoTookView } = this.props.borzMotor;
    const { whoTookNameState } = this.state;
    return (
      <WhoTookViewContainer>
        <Helmet>
          <title>Aracı Alan Kişiler | Borz Motor</title>
        </Helmet>
        <div className="addToModelsViewLabel">
          <span>Kayıtlı olan kişiler</span>
        </div>
        <div>
          <Input
            type="text"
            labelText="Kişiler içinde ara"
            inputID="whoTookView"
            inputHtmlFor="whoTookView"
            onChange={(e) => this.filterBrands(e)}
          />
        </div>
        <div className="allModelsContainer">
          {whoTookNameState && whoTookNameState.length > 0 ? (
            whoTookNameState &&
            whoTookNameState.map(
              ({ _id, whoTook }) =>
                whoTook && (
                  <ModelViewComponent key={_id}>{whoTook}</ModelViewComponent>
                )
            )
          ) : whoTookView && whoTookView.length > 0 ? (
            whoTookView &&
            whoTookView.map(
              ({ _id, whoTook }) =>
                whoTook && (
                  <ModelViewComponent key={_id}>{whoTook}</ModelViewComponent>
                )
            )
          ) : (
            <div>Kayıtlı Kişi Bulunamadı</div>
          )}
        </div>
      </WhoTookViewContainer>
    );
  }
}
export default connect((state) => {
  return state;
}, actions)(WhoTookView);
