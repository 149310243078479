import styled from "styled-components";

const TableContainer = styled.div`
    display: grid;
    table {
        width: 90%;
        text-align: center;
        margin: 0 auto;
        margin: 15px;
    }
    table > thead > tr > th {
        border: 2px solid black;
        padding: 15px;
        margin: 15px;
        font-size: 17px;
    }
    table > tbody {
        margin-top: 15px;
    }
    table > tbody > tr > td {
        border: 2px solid black;
        padding: 15px;
        font-size: 16px;
    }

    table > tbody > tr > td > button {
        border: none;
        width: 100px;
        height: 50px;
        border: 1px black groove;
        background-color: transparent;
    }
`;

export { TableContainer };