import axios from "axios";
import { makeRequest } from "../Utils/Request";
import { development } from "../config";

const requestOptions = {
  "Content-Type": "application/x-www-form-urlencoded",
};

export const addModelDataCreator = (model) => {
  return {
    type: "ADDCARMODEL",
    model,
  };
};

export const addEmployeeDataCreator = (employee) => {
  return {
    type: "ADDEMPLOYEE",
    employee,
  };
};

export const addWhoTookDataCreator = (whoTook) => {
  return {
    type: "ADDWHOTOOK",
    whoTook,
  };
};

export const addWhoSoldDataCreator = (whoSold) => {
  return {
    type: "ADDWHOSOLD",
    whoSold,
  };
};

export const addBrandCreator = (brand) => {
  return {
    type: "ADDBRAND",
    brand,
  };
};

export const addMotorCreator = (motor) => {
  return {
    type: "ADDMOTOR",
    motor,
  };
};

export const addBoxCreator = (box) => {
  return {
    type: "ADDBOX",
    box,
  };
};

export const carBrandListCreator = (brandList) => {
  return {
    type: "VIEWCARBRAND",
    brandList,
  };
};

export const employeeViewCreator = (employeeView) => {
  return {
    type: "VIEWEMPLOYEE",
    employeeView,
  };
};

export const whoTookViewCreator = (whoTookView) => {
  return {
    type: "VIEWWHOTOOK",
    whoTookView,
  };
};

export const whoSoldViewCreator = (whoSoldView) => {
  return {
    type: "VIEWWHOSOLD",
    whoSoldView,
  };
};

export const setCreditCreator = (credit) => {
  return {
    type: "CREDIT",
    credit,
  };
};

export const getCreditCreator = (getCredit) => {
  return {
    type: "GETCREDIT",
    getCredit,
  };
};

export const successCreditCreator = (successCredit) => {
  return {
    type: "CREDITSUCCESS",
    successCredit,
  };
};

export const activeVehicleActionCreator = (active) => {
  return {
    type: "ACTIVEVEHICLE",
    active,
  };
};

export const justShowSellCreator = (justShow) => {
  return {
    type: "JUSTSHOW",
    justShow,
  };
};

export const reservationListCreator = (reservationList) => {
  return {
    type: "RESERVATIONLIST",
    reservationList,
  };
};

export const deActiveVehicleActionCreator = (deActive) => {
  return {
    type: "DEACTIVEVEHICLE",
    deActive,
  };
};

export const carBoxListCreator = (boxList) => {
  return {
    type: "VIEWCARBOX",
    boxList,
  };
};

export const carMotorListCreator = (motorList) => {
  return {
    type: "VIEWCARMOTOR",
    motorList,
  };
};

export const carSaleOfferListCreator = (list) => {
  return {
    type: "VIEWCARSALEOFFER",
    list,
  };
};

export const loginCreator = (login) => {
  return {
    type: "LOGIN",
    login,
  };
};

export const allCarListCreator = (allCarList) => {
  return {
    type: "VIEWALLCAR",
    allCarList,
  };
};

export const carUpdateGetIdCreator = (updateCarId) => {
  return {
    type: "CARUPDATEGETBYID",
    updateCarId,
  };
};

export const addCarSavePending = (carSavePending) => {
  return {
    type: "CARSAVEPENDING",
    carSavePending,
  };
};

export const addCarSaveError = (carSaveError) => {
  return {
    type: "CARSAVEERROR",
    carSaveError,
  };
};

export const carDeleteCreator = (
  carDelete,
  carDeleteSuccess,
  carDeleteData
) => {
  return {
    type: "DELETECAR",
    carDelete,
    carDeleteSuccess,
    carDeleteData,
  };
};

export const aboutDescriptionCreator = (aboutParam) => {
  return {
    type: "ABOUT",
    aboutParam,
  };
};

export const getAboutCreator = (getAboutParam) => {
  return {
    type: "GETABOUT",
    getAboutParam,
  };
};

export const viewModelDataCreator = (modelName) => {
  return {
    type: "VIEWCARMODEL",
    modelName,
  };
};

export const addCarModel = (model) => {
  let addModel = `${development}/api/addModel`;
  return (dispatch) => {
    axios.post(addModel, { model }, requestOptions).then(
      (res) => {
        const addModelData = res.data;
        dispatch(addModelDataCreator(addModelData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const addEmployee = (employee) => {
  let addEmployeeUrl = `${development}/api/addEmployee`;
  return (dispatch) => {
    axios.post(addEmployeeUrl, { employee }, requestOptions).then(
      (res) => {
        const addEmployeeData = res.data;
        dispatch(addEmployeeDataCreator(addEmployeeData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const addWhoTook = (whoTook) => {
  let addWhoTookUrl = `${development}/api/whoTook`;
  return (dispatch) => {
    axios.post(addWhoTookUrl, { whoTook }, requestOptions).then(
      (res) => {
        const addWhoTookData = res.data;
        dispatch(addWhoTookDataCreator(addWhoTookData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const addWhoSold = (whoSold) => {
  let addWhoSoldUrl = `${development}/api/whoSold`;
  return (dispatch) => {
    axios.post(addWhoSoldUrl, { whoSold }, requestOptions).then(
      (res) => {
        const addWhoSoldData = res.data;
        dispatch(addWhoSoldDataCreator(addWhoSoldData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const addBrand = (brand) => {
  let addBrandUrl = `${development}/api/addBrand`;
  return (dispatch) => {
    axios.post(addBrandUrl, { brand }, requestOptions).then(
      (res) => {
        const addBrandData = res.data;
        dispatch(addBrandCreator(addBrandData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const addBox = (box) => {
  let addBoxUrl = `${development}/api/addBox`;
  return (dispatch) => {
    axios.post(addBoxUrl, { box }, requestOptions).then(
      (res) => {
        const addBoxData = res.data;
        dispatch(addBoxCreator(addBoxData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const addMotor = (motor) => {
  let addMotorUrl = `${development}/api/addMotor`;
  return (dispatch) => {
    axios.post(addMotorUrl, { motor }, requestOptions).then(
      (res) => {
        const addMotorData = res.data;
        dispatch(addMotorCreator(addMotorData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const carListView = () => {
  let viewModelUrl = `${development}/api/allModelView`;
  return (dispatch) => {
    axios.get(viewModelUrl).then(
      (res) => {
        const viewModelData = res.data;
        dispatch(viewModelDataCreator(viewModelData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const carBrandListView = () => {
  let carBrandListUrl = `${development}/api/allBrandsView`;
  return (dispatch) => {
    axios.get(carBrandListUrl).then(
      (res) => {
        const carBrandListData = res.data;
        dispatch(carBrandListCreator(carBrandListData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const employeeView = () => {
  let employeeViewUrl = `${development}/api/allEmployeeView`;
  return (dispatch) => {
    axios.get(employeeViewUrl).then(
      (res) => {
        const employeeViewData = res.data;
        dispatch(employeeViewCreator(employeeViewData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const whoTookView = () => {
  let whoTookViewUrl = `${development}/api/whoTook`;
  return (dispatch) => {
    axios.get(whoTookViewUrl).then(
      (res) => {
        const whoTookViewData = res.data;
        dispatch(whoTookViewCreator(whoTookViewData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const whoSoldView = () => {
  let whoSoldViewUrl = `${development}/api/whoSold`;
  return (dispatch) => {
    axios.get(whoSoldViewUrl).then(
      (res) => {
        const whoSoldViewData = res.data;
        dispatch(whoSoldViewCreator(whoSoldViewData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const activeVehicle = () => {
  let ActiveVehicleUrl = `${development}/api/activeVehicle`;
  return (dispatch) => {
    axios.get(ActiveVehicleUrl).then(
      (res) => {
        const ActiveVehicleUrlData = res.data;
        dispatch(activeVehicleActionCreator(ActiveVehicleUrlData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const justShowSell = () => {
  let justShowSellUrl = `${development}/api/justShowSell`;
  return (dispatch) => {
    axios.get(justShowSellUrl).then(
      (res) => {
        const justShowSellUrlData = res.data;
        dispatch(justShowSellCreator(justShowSellUrlData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const reservationList = () => {
  let reservationListUrl = `${development}/api/reservation/plus`;
  return (dispatch) => {
    axios.get(reservationListUrl).then(
      (res) => {
        const reservationListUrlData = res.data;
        dispatch(reservationListCreator(reservationListUrlData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const deActiveVehicle = () => {
  let deActiveVehicleUrl = `${development}/api/deActiveVehicle`;
  return (dispatch) => {
    axios.get(deActiveVehicleUrl).then(
      (res) => {
        const deActiveVehicleUrlData = res.data;
        dispatch(deActiveVehicleActionCreator(deActiveVehicleUrlData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const carBoxListView = () => {
  let carBoxListUrl = `${development}/api/allBoxView`;
  return (dispatch) => {
    axios.get(carBoxListUrl).then(
      (res) => {
        const carBoxListData = res.data;
        dispatch(carBoxListCreator(carBoxListData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const carMotorListView = () => {
  let carMotorListUrl = `${development}/api/allMotorView`;
  return (dispatch) => {
    axios.get(carMotorListUrl).then(
      (res) => {
        const carMotorListData = res.data;
        dispatch(carMotorListCreator(carMotorListData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const carSaleOfferListView = () => {
  let url = `${development}/api/carSaleOffer`;
  return (dispatch) => {
    axios.get(url).then(
      (res) => {
        const data = res.data;
        dispatch(carSaleOfferListCreator(data));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const login = (username, password) => {
  let loginUrl = `${development}/api/user/login`;
  const options = {
    url: loginUrl,
    method: "POST",
    headers: {
      Accept: requestOptions,
    },
    data: {
      username: username,
      password: password,
    },
  };
  return (dispatch) => {
    axios(options).then(
      (res) => {
        const loginDataParse = res.data;
        const loginData = loginDataParse;
        dispatch(loginCreator(loginData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const allCarListView = () => {
  let allCarListUrl = `${development}/api/allVehicleViews`;
  return (dispatch) => {
    axios.get(allCarListUrl).then(
      (res) => {
        const allCarListData = res.data;
        dispatch(allCarListCreator(allCarListData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const setCredit = ({ maxValue, minValue, range, yearValue }) => {
  let setCreditUrl = `${development}/api/setCredit`;
  let urlencoded = new URLSearchParams();
  urlencoded.append("maxValue", maxValue);
  urlencoded.append("minValue", minValue);
  urlencoded.append("range", range);
  urlencoded.append("yearValue", yearValue);
  return (dispatch) => {
    makeRequest("POST", setCreditUrl, urlencoded, requestOptions).then(
      (res) => {
        if (res.status === 200) {
          const setCreditData = res.data;
          const success = res.statusText;
          dispatch(setCreditCreator(setCreditData));
          dispatch(successCreditCreator(success));
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const getCredit = () => {
  let getCreditUrl = `${development}/api/getCredit`;
  return (dispatch) => {
    axios.get(getCreditUrl, requestOptions).then(
      (res) => {
        const getCreditData = res.data;
        dispatch(getCreditCreator(getCreditData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const carUpdateGetId = (updateCarId) => {
  let carUpdateUrl = `${development}/api/carIdUpdate?_id=${updateCarId}`;
  return (dispatch) => {
    axios.get(carUpdateUrl).then(
      (res) => {
        const getUpdateData = res.data;
        dispatch(carUpdateGetIdCreator(getUpdateData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const carDelete = (_id) => {
  let carDeleteUrl = `${development}/api/vehicle/remove`;
  return (dispatch) => {
    let urlencoded = new URLSearchParams();
    urlencoded.append("_id", _id);

    makeRequest("DELETE", carDeleteUrl, urlencoded, requestOptions).then(
      (res) => {
        let carData = JSON.parse(res);
        const CarDeleteMessage = carData.message;
        const CarDeleteSuccess = carData.success;
        const CarDeleteData = carData.data;
        dispatch(
          carDeleteCreator(CarDeleteMessage, CarDeleteSuccess, CarDeleteData)
        );
        alert("Silme işlemi başarılı.");
      },
      (err) => {
        console.log(err);
        alert("Silme işleminde hata oluştu.");
      }
    );
  };
};

export const aboutDescription = ({ _id, about, mission, vision }) => {
  let urlencoded = new URLSearchParams();
  urlencoded.append("_id", _id);
  urlencoded.append("about", about);
  urlencoded.append("mission", mission);
  urlencoded.append("vision", vision);

  let aboutDescriptionUrl = `${development}/api/setAbout`;
  return (dispatch) => {
    makeRequest("POST", aboutDescriptionUrl, urlencoded, requestOptions).then(
      (res) => {
        const aboutDescriptionData = res.data;
        dispatch(aboutDescriptionCreator(aboutDescriptionData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const getAbout = () => {
  let getAboutUrl = `${development}/api/getAbout`;
  return (dispatch) => {
    axios.get(getAboutUrl).then(
      (res) => {
        const getAboutData = res.data;
        dispatch(getAboutCreator(getAboutData));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};
