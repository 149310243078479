import styled from "styled-components";

const GetCarsInfoContainer = styled.div`
  .showActiveHead {
  }
  .carContainer {
    background-color: #e1e5ea;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 10px 0;
    display: grid;
    grid-template-columns: repeat(9, 11.1%);
    &:last-child {
      margin-bottom: 0;
    }
    span {
      text-transform: capitalize;
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .carList {
    display: block;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .justMobileList {
    display: none;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
`;

const ShowActiveData = styled.ul`
  margin: 0;
  display: grid;
  grid-template-columns: repeat(9, 11.1%);
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  padding: 10px;
  &:last-child {
    border-bottom: none;
  }
  li {
    text-transform: capitalize;
  }
  @media screen and (max-width: 800px) {
    display: block;
    margin: auto;
    width: 100%;
    max-width: 220px;
    border-bottom: 1px #d2d2d2 dashed;
  }
`;

export { GetCarsInfoContainer, ShowActiveData };
