export const news = {
    features:{
        aracEkleme: [
            "Araç plakası mutlaka girilmelidir.",
            "Tramer kısmında tramer bulunmuyor yazısını görmek için değer olarak '0' girilmelidir.",
            "İlan görselleri maksimum 25 adet olmalıdır.",
            "Araç masrafı ve alım tutarı mutlaka girilmelidir.",
        ],
        aracGuncelleme: [
            "Araç satış tarihi mutlaka girilmelidir.",
            "Araç satış tutarı mutlaka girilmelidir",
        ],
        nelerYeni: [
            "Aracı bulan kişiyi kaydetmek için sekme eklendi",
            "Aracı bulan kişiyi ilan içersinden seçebilme",
            "Aracı kim satın almaya gitti",
            "Aracı satın almaya giden personel ne kadar indirim aldı ",
            "Personel aldığı indirim üzerinden ne kadar prim aldı ( ilan içerisinde miktar girildiği zaman otomatik görüntülenebilme )"
        ]
    }
}