import React, { Component } from "react";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import { Link } from "react-router-dom";

/* components */
import Header from "./Components/Header/Loadable";
import SideMenu from "./Components/SideMenu/Loadable";
import MobilMenu from "./Components/MobileMenu/Loadable";
/* components */

/* pages */
import Login from "./Containers/Login";
import Dashboard from "./Containers/Dashboard";
import AddCar from "./Containers/AddCar";
import CarBrandSave from "./Containers/CarBrandSave";
import CarModelSave from "./Containers/CarModelSave";
import CarMotorSave from "./Containers/CarMotorSave";
import CarBoxSave from "./Containers/CarBoxSave";
import CarsView from "./Containers/CarsView";
import About from "./Containers/About";
import Credit from "./Containers/Credit";
import GetCars from "./Containers/GetCarsInfo/";
import EmployeeProcess from "./Containers/EmployeeProcess";
import WhoTookProccess from "./Containers/WhoTookProcess";
import WhoSoldProccess from "./Containers/WhoSoldProcess";
import ReservationList from "./Containers/ReservationList";
import ReservationAdd from "./Containers/ReservationAdd";
import CarSaleOffers from "./Containers/CarSaleOffers";
import BonusList from "./Containers/BonusList";
import BonusAdd from "./Containers/BonusAdd";
import BonusReport from "./Containers/BonusReport";
/* pages */

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuControl: true,
    };
    this.mobileMenuClick = this.mobileMenuClick.bind(this);
    this.logOut = this.logOut.bind(this);
  }

  mobileMenuClick() {
    const { menuControl } = this.state;
    this.setState({
      menuControl: !menuControl,
    });
  }

  logOut() {
    localStorage.removeItem("token");
  }

  render() {
    const login = localStorage.getItem("token");
    const username = localStorage.getItem("username");
    console.log("username:", username);
    const { menuControl } = this.state;
    return (
      <BrowserRouter>
        <Switch>
          {login && (
            <React.Fragment>
              <header>
                <div
                  className="mobilMenuCloseIcon menuIcon"
                  onClick={this.mobileMenuClick}
                >
                  <div className="nav-icon">
                    <div></div>
                  </div>
                </div>
                <Header />
              </header>
              <section>
                {!menuControl && (
                  <React.Fragment>
                    <div
                      className="mobilMenuCloseIcon"
                      onClick={this.mobileMenuClick}
                    >
                      x
                    </div>
                    <div className="mobileMenu">
                      <MobilMenu>
                        {username ? (
                          <li
                            style={{
                              backgroundColor: "#567ea0",
                              marginBottom: "10px",
                              padding: "10px",
                            }}
                          >
                            <span
                              style={{
                                display: "block",
                                margin: "auto",
                                width: "100%",
                                textAlign: "center",
                                color: "#fff",
                                textTransform: "uppercase",
                              }}
                            >
                              Hoşgeldin, <strong>{username}</strong>
                            </span>
                          </li>
                        ) : (
                          ""
                        )}
                        {username !== "borzplus" ? (
                          <div>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/">Anasayfa</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/araclari-gor">Araçları Gör</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/arac-ekle">Araç Ekle</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/marka-kaydet">Marka Kaydet</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/paket-kaydet">Paket Kaydet</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/motor-kaydet">Motor Kaydet</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/model-kaydet">Model Kaydet</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/personel">Aracı Bulan</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/arac-alan">Aracı Alan</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/arac-satan">Aracı Satan</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/ilanlar">İlanlar</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/kredi">Kredi Hesaplama</Link>
                            </li>
                            <li onClick={this.mobileMenuClick}>
                              <Link to="/hakkimizda">Hakkımızda</Link>
                            </li>
                          </div>
                        ) : (
                          ""
                        )}
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/arac-satis-teklifleri">
                            Araç Satış Teklifleri
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/prim-raporu">
                            Prim Raporu
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/prim-listesi">
                            Prim Listesi
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/rezervasyon-liste">
                            Rezervasyon Listesi
                          </Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link to="/rezervasyon-ekle">Rezervasyon Ekle</Link>
                        </li>
                        <li onClick={this.mobileMenuClick}>
                          <Link onClick={this.logOut}>Çıkış Yap</Link>
                        </li>
                      </MobilMenu>
                    </div>
                  </React.Fragment>
                )}
                <div className="sideMenu">
                  <SideMenu />
                </div>
                <div className="content">
                  {username !== "borzplus" ? (
                    <div>
                      <Route exact path="/" component={Dashboard} />
                      <Route exact path="/arac-ekle" component={AddCar} />
                      <Route
                        exact
                        path="/marka-kaydet"
                        component={CarBrandSave}
                      />
                      <Route
                        exact
                        path="/model-kaydet"
                        component={CarModelSave}
                      />
                      <Route
                        exact
                        path="/paket-kaydet"
                        component={CarBoxSave}
                      />
                      <Route
                        exact
                        path="/personel"
                        component={EmployeeProcess}
                      />
                      <Route
                        exact
                        path="/arac-alan"
                        component={WhoTookProccess}
                      />
                      <Route
                        exact
                        path="/arac-satan"
                        component={WhoSoldProccess}
                      />
                      <Route
                        exact
                        path="/motor-kaydet"
                        component={CarMotorSave}
                      />
                      <Route exact path="/ilanlar" component={CarsView} />
                      <Route exact path="/kredi" component={Credit} />
                      <Route exact path="/hakkimizda" component={About} />
                    </div>
                  ) : (
                    ""
                  )}
                  <Route
                    exact
                    path="/arac-satis-teklifleri"
                    component={CarSaleOffers}
                  />
                  <Route
                    exact
                    path="/prim-ekle"
                    component={BonusAdd}
                  />
                  <Route
                    exact
                    path="/prim-raporu"
                    component={BonusReport}
                  />
                  <Route
                    exact
                    path="/prim-listesi"
                    component={BonusList}
                  />
                  <Route
                    exact
                    path="/rezervasyon-liste"
                    component={ReservationList}
                  />
                  <Route
                    exact
                    path="/rezervasyon-ekle"
                    component={ReservationAdd}
                  />
                  <Route exact path="/araclari-gor" component={GetCars} />
                </div>
              </section>
            </React.Fragment>
          )}
          {!login && <Route exact path="/" component={Login} />}
          <Route render={() => <Redirect to="/" />} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
