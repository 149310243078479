import React, { Component } from "react";
import InputComponent from "../../Components/FormElements/Input/Loadable";
import ButtonComponent from "../../Components/FormElements/Button/Loadable";
import { development } from "../../config";
import { DatePicker } from "antd";

import {  
  InputContainerDate,
  InputContainer,
  ButtonContainer
} from "./style";
import { connect } from "react-redux";
import axios from "axios";

let actions = require("../../Actions");

class ReservationAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: "",
            plate: "",
            fullname: "",
            make: "",
            model: "",
            description: "",
            reservationDate: "",
            date: "",
            hour: "",
            source: "BORZ_PLUS"            
        };
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
        this.save = this.save.bind(this);
    }
       
    inputChange(e) {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        this.setState({
        [name]: value,
        });
    }

    onChangeDatePicker(date, dateString) {
        this.setState({
        date: dateString,
        });
    }    

    save(e) {        
        e.preventDefault();
        if(this.state.phoneNumber === null || this.state.phoneNumber === "") {
                alert("Telefon numarası gereklidir.");
            }
            else if(this.state.plate === null || this.state.plate === "") {
                alert("Plaka gereklidir.");
            }
            else if(this.state.fullname === null || this.state.fullname === "") {
                alert("Tam Ad gereklidir.");
            }
            else if(this.state.make === null || this.state.make === "") {
                alert("Marka gereklidir.");
            }
            else if(this.state.model === null || this.state.model === "") {
                alert("Model gereklidir.");
            }
            else if(this.state.date === null || this.state.date === "") {
                alert("Rezervasyon tarihi gereklidir.");
            }
            else if(this.state.hour === null || this.state.hour === "") {
                alert("Rezervasyon saati gereklidir.");
            }
            else {
                try {

                    axios({
                        method: "POST",
                        url: `${development}/api/reservation/add`,
                        headers: {},
                        data: {
                            "phoneNumber": this.state.phoneNumber,
                            "plate": this.state.plate,
                            "fullname": this.state.fullname,
                            "make": this.state.make,
                            "model": this.state.model,
                            "description": this.state.description,
                            "reservationDate": new Date(this.state.date.split('-')[0], (Number(this.state.date.split('-')[1]) - 1), this.state.date.split('-')[2], this.state.hour.split(':')[0], this.state.hour.split(':')[1]),
                            "source": this.state.source
                        }
                    });
                    
                    alert('Rezervasyon başarıyla kaydedildi.');
                    window.location = "/rezervasyon-liste";

                } catch {
                    alert('Tarih hatalı girildi.');
                }
            }
    }

    render() {
        console.log('state=> ', this.state);
        return (
            <form onSubmit={this.save}>
            <InputContainerDate>
            <div>
              <p>
                 Rezervasyon Tarihi                
              </p>
            </div>
            <DatePicker
              placeholder="Rezervasyon Tarihi"
              onChange={this.onChangeDatePicker}
            />
            <InputComponent
              labelText="Rezervasyon Saati (Örn: 16:30)"
              type="text"
              name="hour"
              inputID="hour"
              inputHtmlFor="hour"
              onChange={(e) => this.inputChange(e)}
            />
          </InputContainerDate>
            <InputContainer>
            <InputComponent
              labelText="Ad Soyad"
              type="text"
              name="fullname"
              inputID="fullname"
              inputHtmlFor="fullname"
              onChange={(e) => this.inputChange(e)}
            />
            <InputComponent
              labelText="Plaka"
              type="text"
              name="plate"
              inputID="plate"
              inputHtmlFor="plate"
              onChange={(e) => this.inputChange(e)}
            />
            </InputContainer>
            <InputContainer>
            <InputComponent
              labelText="Marka"
              type="text"
              name="make"
              inputID="make"
              inputHtmlFor="make"
              onChange={(e) => this.inputChange(e)}
            />
            <InputComponent
              labelText="Model"
              type="text"
              name="model"
              inputID="model"
              inputHtmlFor="model"
              onChange={(e) => this.inputChange(e)}
            />
            </InputContainer>
            <InputContainer>
            <InputComponent
              labelText="Telefon Numarası"
              type="text"
              name="phoneNumber"
              inputID="phoneNumber"
              inputHtmlFor="phoneNumber"
              onChange={(e) => this.inputChange(e)}
            />
            <InputComponent
              labelText="Notlar"
              type="text"
              name="description"
              inputID="description"
              inputHtmlFor="description"              
              onChange={(e) => this.inputChange(e)}
            />
            </InputContainer>
            <ButtonContainer>
                <ButtonComponent type="submit" label="Kaydet" />
            </ButtonContainer>
            </form>
        );
    }
}

export default connect((state) => {
  return state;
}, actions)(ReservationAdd);