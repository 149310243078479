import React, { Component } from "react";
import Input from "../../Components/FormElements/Input/Loadable";
import Button from "../../Components/FormElements/Button/Loadable";

import { WhoSoldProcessContainer } from "./style";

import WhoSoldView from "../WhoSoldView";

import { connect } from "react-redux";
let actions = require("../../Actions");

class WhoSoldProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
    this.inputChange = this.inputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.borzMotor.whoSold !== this.props.borzMotor.whoSold) {
      window.location.reload();
    }
  }

  submitForm(e) {
    e.preventDefault();
    const { inputValue } = this.state;
    this.props.addWhoSold(inputValue);
    this.setState({
      inputValue,
    });
  }

  inputChange(e) {
    let inputValue = e.target.value;
    this.setState({ inputValue });
  }
  render() {
    return (
      <WhoSoldProcessContainer>
        <form onSubmit={this.submitForm}>
          <Input
            onChange={this.inputChange}
            labelText="Aracı Satan Kişi Adı Soyadı"
            type="text"
            inputID="WhoSoldProcess"
            inputHtmlFor="WhoSoldProcess"
          />
          <Button type="submit" label="Kişi Kaydet" />
        </form>
        <WhoSoldView />
      </WhoSoldProcessContainer>
    );
  }
}
export default connect((state) => {
  return state;
}, actions)(WhoSoldProcess);
