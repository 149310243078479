import React, { Component } from 'react'
import Input from '../../Components/FormElements/Input/Loadable'
import Button from '../../Components/FormElements/Button/Loadable'

import { EmployeeProcessContainer } from './style'

import EmployeeView from '../EmployeeView'

import {connect} from 'react-redux';
let actions = require('../../Actions');


class EmployeeProcess extends Component {
    constructor(props){
        super(props);
        this.state={
            inputValue: ""
        }
        this.inputChange = this.inputChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }



    componentDidUpdate(prevProps, prevState) {
        if(prevProps.borzMotor.employee !== this.props.borzMotor.employee){
            window.location.reload()
        }
    }


    submitForm(e){
        e.preventDefault();
        const { inputValue } = this.state;
        this.props.addEmployee(inputValue)
        this.setState({
             inputValue
        });
    }

    inputChange(e){
        let inputValue = e.target.value;
        this.setState({inputValue})
    }
    render() {
        return (
            <EmployeeProcessContainer>
                <form onSubmit={this.submitForm}>
                    <Input
                        onChange={ this.inputChange }
                        labelText="Aracı Bulan Kişi Adı Soyadı"
                        type="text"
                        inputID="EmployeeProcess"
                        inputHtmlFor="EmployeeProcess"
                    />
                    <Button
                        type="submit"
                        label="Kişi Kaydet"
                    />
                </form>
                <EmployeeView/>
            </EmployeeProcessContainer>
        )
    }
}
export default connect((state)=>{return state},actions)(EmployeeProcess)
