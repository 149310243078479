import styled from 'styled-components'

const CreditContainer = styled.div`
    display: flex;
`;

const CreditGroup = styled.div `

`;

const CreditFormView = styled.div `
width: 50%;
padding: 30px 10px;
`;

const CreditForm = styled.div `
width: 50%;
`;

export { CreditContainer, CreditGroup, CreditFormView, CreditForm }