import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { WhoSoldViewContainer } from "./style";

import ModelViewComponent from "../../Components/ModelView/Loadable";
import Input from "../../Components/FormElements/Input/Loadable";

import { connect } from "react-redux";
let actions = require("../../Actions");

class WhoSoldView extends Component {
  state = {
    whoSoldNameState: [],
    whoSoldListState: [],
  };
  componentDidMount() {
    this.props.whoSoldView();
  }

  filterBrands(e) {
    const { whoSoldView } = this.props.borzMotor;
    const findTo = e.target.value;
    findTo.length === 0 && this.setState({ whoSoldNameState: [] });
    whoSoldView &&
      whoSoldView.filter(
        (emp) =>
          emp.whoSold === findTo && this.setState({ whoSoldNameState: [emp] })
      );
  }

  render() {
    const { whoSoldView } = this.props.borzMotor;
    const { whoSoldNameState } = this.state;
    return (
      <WhoSoldViewContainer>
        <Helmet>
          <title>Aracı Satan Kişiler | Borz Motor</title>
        </Helmet>
        <div className="addToModelsViewLabel">
          <span>Kayıtlı olan kişiler</span>
        </div>
        <div>
          <Input
            type="text"
            labelText="Kişiler içinde ara"
            inputID="whoSoldView"
            inputHtmlFor="whoSoldView"
            onChange={(e) => this.filterBrands(e)}
          />
        </div>
        <div className="allModelsContainer">
          {whoSoldNameState && whoSoldNameState.length > 0 ? (
            whoSoldNameState &&
            whoSoldNameState.map(
              ({ _id, whoSold }) =>
                whoSold && (
                  <ModelViewComponent key={_id}>{whoSold}</ModelViewComponent>
                )
            )
          ) : whoSoldView && whoSoldView.length > 0 ? (
            whoSoldView &&
            whoSoldView.map(
              ({ _id, whoSold }) =>
                whoSold && (
                  <ModelViewComponent key={_id}>{whoSold}</ModelViewComponent>
                )
            )
          ) : (
            <div>Kayıtlı Kişi Bulunamadı</div>
          )}
        </div>
      </WhoSoldViewContainer>
    );
  }
}
export default connect((state) => {
  return state;
}, actions)(WhoSoldView);
