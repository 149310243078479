import React, { Component } from "react";

import axios from "axios";
import { development } from "../../config";

import { TableContainer } from "./style";

import { connect } from "react-redux";
let actions = require("../../Actions");

class BonusList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  delete = (id) => {
    let url = `${development}/api/bonus/${id}`;
    axios.post(url, {  }).then(
      (res) => {
        alert("Prim başarıyla silindi.");
        window.location.reload();
      },
      (err) => {
        alert("Prim silinirken bir hata oluştu.");
        window.location.reload();
      }
    );
  }

  componentDidMount() {
    let url = `${development}/api/bonus`;
    axios.get(url).then(
      (res) => {
        const data = res.data.filter(x => x.bonus.isDeleted == false);
        this.setState({ data: data });
        console.log(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  render() {
    return (
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Prim Adı</th>
              <th>Prim Tutarı (TL)</th>
              <th>Prim Tutarı (%)</th>
              <th>Baz Alış</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.reverse().map((listValue, index) => {
              return (
                <tr key={index}>
                  <td>{index+1}</td>
                  <td>{listValue?.bonus?.bonusName}</td>
                  <td>{listValue?.bonus?.bonusPrice ?? 0}TL</td>
                  <td>{listValue?.bonus?.bonusPricePercentage ?? 0}%</td>
                  <td>{listValue?.bonus?.percentageDirection == "Purchase_Price" ? "Araç Alış Fiyatı" : listValue?.bonus?.percentageDirection == "Sell_Price" ? "Araç Satış Fiyatı" : listValue?.bonus?.percentageDirection == "Discount_Price" ? "Araç Alım İndirim Oranı" : "" }</td>
                  <td>
                    <button onClick={() => this.delete(listValue?._id)}>Sil</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableContainer>
    );
  }
}
export default connect((state) => {
  return state;
}, actions)(BonusList);
