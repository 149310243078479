import React, { Component } from "react";
import { ReservationListContainer, ShowActiveData } from "./style";

import { connect } from "react-redux";
import { reservationList } from "../../Actions";

import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

class ReservationList extends Component {
  state = {
    carInfosHead: [
      "tarih",
      "tam ad",
      "plaka",
      "marka",
      "model",
      "tel no",
      "not",
      "sms?"      
    ],
  };

  componentDidMount() {
    this.props.reservationList();
  }

  render() {
    const { reservationList } = this.props.borzMotor;
    const { carInfosHead } = this.state;

    console.log('reservation list => ', reservationList);
    console.log('this.props.borzMotor => ', this.props.borzMotor);

    return(
      <ReservationListContainer>
        <div className="carContainer">
          {carInfosHead && carInfosHead.map((head) => <span>{head}</span>)}
        </div>
        <div className="carContainer carList">
          { 
          
            reservationList && reservationList.map((x => {
              return <ShowActiveData>
                <li>{moment(x.reservation.reservationDate).format('Do/MM/YYYY H:mm')}</li>
                <li>{x.reservation.fullname}</li>
                <li>{x.reservation.plate}</li>
                <li>{x.reservation.make}</li>
                <li>{x.reservation.model}</li>
                <li>{x.reservation.phoneNumber}</li>
                <li>{x.reservation.description}</li>
                <li>{x.reservation.isCompleted ? "Evet" : "Hayır"}</li>
              </ShowActiveData>
          }))

          }
        </div>
        <div className="justMobileList">
          { reservationList && reservationList.map((x => {
            return <ShowActiveData>
                <li>
                  <span>Tarih: </span>
                  <span>
                    <strong>{moment(x.reservation.reservationDate).format("Do/MM/YYYY H:mm")}</strong>
                  </span>
                </li>
                <li>
                  <span>Tam Ad: </span>
                  <span>
                    <strong>{x.reservation.fullname}</strong>
                  </span>
                </li>
                <li>
                  <span>Plaka: </span>
                  <span>
                    <strong>{x.reservation.plate}</strong>
                  </span>
                </li>
                <li>
                  <span>Marka: </span>
                  <span>
                    <strong>{x.reservation.make}</strong>
                  </span>
                </li>
                <li>
                  <span>Model: </span>
                  <span>
                    <strong>{x.reservation.model}</strong>
                  </span>
                </li>
                <li>
                  <span>Tel No: </span>
                  <span>
                    <strong>{x.reservation.phoneNumber}</strong>
                  </span>
                </li>
                <li>
                  <span>Not: </span>
                  <span>
                    <strong>{x.reservation.description}</strong>
                  </span>
                </li>
                <li>
                  <span>Sms?: </span>
                  <span>
                    <strong>{x.reservation.isCompleted ? "Evet" : "Hayır"}</strong>
                  </span>
                </li>
              </ShowActiveData>
          }))
          }
        </div>
      </ReservationListContainer>
    );
  }

}

export default connect(
  (state) => {
    return state;
  },
  { reservationList }
)(ReservationList);
