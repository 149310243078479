export const tramerData = [
    {
        type: 'radio',
        text: 'tavan',
        name: 'tavan',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'Bagaj',
        name: 'bagaj',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'kaput',
        name: 'kaput',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sol ön çamurluk',
        name: 'solOnCamurluk',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sol arka çamurluk',
        name: 'solArkaCamurluk',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sol ön kapı',
        name: 'solOnKapi',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sol arka kapı',
        name: 'solArkaKapi',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sağ ön çamurluk',
        name: 'sagOnCamurluk',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sağ arka çamurluk',
        name: 'sagArkaCamurluk',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sağ ön kapı',
        name: 'sagOnKapi',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    },
    {
        type: 'radio',
        text: 'sağ arka kapı',
        name: 'sagArkaKapi',
        tramer: [
            {
                name:"boyasız"
            },
            {
                name: "boyalı"
            },
            {
                name: "lokal boyalı"
            },
            {
                name: "değişen"
            }
        ]
    }
  ];