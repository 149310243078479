
import styled from "styled-components";

const Container = styled.div`
    margin: 15px;
    div {
        border-radius: 5px;
    }
    .yearSection {
        min-height: 100px;
        border: 1px solid black;
        padding: 15px;
        display: flex;
        justify-content: center;
    }
    .active {
        font-weight: bold;
    }

    .monthPart {
        border: 1px black groove;
        font-size: 20px;
        font-weight: bold;
        padding: 25px;
        min-height: 75px;
        margin-top: 20px;
    }

    .monthDataList {
        border: 1px solid black;
        min-height: 75px;
        max-width: 95%;
        margin: 0 auto;
        margin-top: 15px;        
    }

    table {
        width: 90%;
        text-align: center;
        margin: 0 auto;
        margin: 15px;
        margin-left: 5%;
    }
    table > thead > tr > th {
        border: 1px solid black;
        padding: 15px;
        margin: 15px;
        font-size: 17px;
    }
    table > tbody {
        margin-top: 15px;
    }
    table > tbody > tr > td {
        border: 1px solid black;
        padding: 15px;
        font-size: 16px;
    }

    table > tbody > tr > td > button {
        border: none;
        width: 100px;
        height: 50px;
        border: 1px black groove;
        background-color: transparent;
    }
`;

export { Container };