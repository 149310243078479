import React, { Component } from "react";

import axios from "axios";
import { development } from "../../config";

import { connect } from "react-redux";
let actions = require("../../Actions");

class CarSaleOffers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      data: [],
    };
    this.inputChange = this.inputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  showParts(vehicleParts) {
    let x = "";
    for (let i = 0; i < vehicleParts.length; i++) {
      x += `${vehicleParts[i].name}: ${vehicleParts[i].value} \n`;
    }
    alert(x);
  }

  giveOffer(offer, id, offered, ourOffer) {
    if (offered && offered == true && ourOffer && ourOffer != "") {
      alert(ourOffer);
    } else if (!offered || offered == false) {
      let teklif = prompt(
        `${offer.model} model ${offer.marka} araba için teklifinizi giriniz. \nÖrnek: 350.000 TL veya 2020 model Mercedes ile takas.`
      );
      if (teklif) {
        let url = `${development}/api/carSaleOffer/${id}`;
        axios.post(url, { ourOffer: teklif }).then(
          (res) => {
            if (offer?.phoneNumber) {
              while (offer.phoneNumber.includes(" ")) {
                offer.phoneNumber = offer.phoneNumber.replace(" ", "");
              }
              while (offer.phoneNumber.includes("(")) {
                offer.phoneNumber = offer.phoneNumber.replace("(", "");
              }
              while (offer.phoneNumber.includes(")")) {
                offer.phoneNumber = offer.phoneNumber.replace(")", "");
              }
              while (offer.phoneNumber.includes("-")) {
                offer.phoneNumber = offer.phoneNumber.replace("-", "");
              }
              while (offer.phoneNumber.includes("/")) {
                offer.phoneNumber = offer.phoneNumber.replace("/", "");
              }

              if (offer.phoneNumber.startsWith("05")) {
                offer.phoneNumber = "9" + offer.phoneNumber;
              }
              if (offer.phoneNumber.startsWith("5")) {
                offer.phoneNumber = "90" + offer.phoneNumber;
              }

              let txt = `Merhabalar Efendim, Aracınızı değerlendireceğimiz tahmini teklifimiz: ${teklif} 'dir. \n Onaylayıp randevu oluşturmamız için bu mesaja ONAYLIYORUM yazmanız yeterlidir. \n Müşteri danışmanımız sizi en kısa sürede arayacaktır. İyi günler dileriz. \n Not: Fiyat öngörü niteliğindedir, kesin hüküm taşımaz. \n Borz Motor - Hızlı Sat ekibi.`;
              txt = encodeURIComponent(txt);

              let whatsappMessageUrl = `https://wa.me/${offer.phoneNumber}?text=${txt}`;

              // window.open(
              //   `https://wa.me/${offer.phoneNumber}?text=${txt}`,
              //   'chromeWindow',
              //   'popup'
              // );

              window.location.href = whatsappMessageUrl;
            }
          },
          (err) => {
            console.log(err);
          }
        );
      }
    } else alert("Sistemde teklif yok.");
  }

  componentDidMount() {
    let url = `${development}/api/carSaleOffer`;
    axios.get(url).then(
      (res) => {
        const data = res.data;
        this.setState({ data: data });
        console.log(data);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.borzMotor.motor !== this.props.borzMotor.motor) {
      window.location.reload();
    }
  }

  submitForm(e) {
    e.preventDefault();
    const { inputValue } = this.state;
    this.props.addMotor(inputValue);
  }

  inputChange(e) {
    let inputValue = e.target.value;
    this.setState({ inputValue });
  }

  render() {
    return (
      <div>
        <table
          style={{
            display: "block",
            width: "100%",
            height: "85vh",
            padding: "15px",
            margin: 15,
          }}
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Marka</th>
              <th>Model</th>
              <th>Motor</th>
              <th>Paket</th>
              <th>Vites</th>
              <th>Kilometre</th>
              <th>Müşteri Ad Soyad</th>
              <th>Telefon Numarası</th>
              <th>Mail Adresi</th>
              <th>Tramer</th>
              <th>Takas</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.reverse().map((listValue, index) => {
              return (
                <tr
                  key={index}
                  style={{
                    height: "10vh",
                    overflowY: "scroll",
                    wordWrap: "break-word",
                    border: "1px black solid",
                    backgroundColor:
                      listValue?.offer?.offered == null ||
                      listValue?.offer?.offered == false
                        ? "#f1807e"
                        : "#a1eca7",
                  }}
                >
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.marka}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {`${
                      listValue?.offer?.modelName?.toUpperCase() ?? "-YOK-"
                    } ${listValue?.offer?.model?.toUpperCase()}`}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.engine?.toUpperCase()}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.packet?.toUpperCase()}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.transmission?.toUpperCase()}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.kilometer}km
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.fullName?.toUpperCase()}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.phoneNumber}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.mailAdress}
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.tramer}₺
                  </td>
                  <td
                    style={{
                      padding: 5,
                      margin: 5,
                      width: 150,
                      borderRight: "1px solid black",
                      textAlign: "center",
                    }}
                  >
                    {listValue?.offer?.exchange &&
                    listValue?.offer?.exchange == true
                      ? "Evet"
                      : "Hayır"}
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        this.showParts(listValue?.offer?.vehicleParts)
                      }
                      style={{ margin: 5, width: "10vh", minWidth: 100 }}
                    >
                      Parçalar
                    </button>
                    <button
                      onClick={() =>
                        this.giveOffer(
                          listValue?.offer,
                          listValue?._id,
                          listValue?.offer?.offered,
                          listValue?.offer?.ourOffer
                        )
                      }
                      style={{
                        margin: 5,
                        width: "10vh",
                        minWidth: 100,
                      }}
                    >
                      Teklif
                    </button>
                  </td>
                  {/* <td>
                                    {listValue?.offer?.vehicleParts ? (
                                        <ul style={{padding: '10px', fontSize: '11px'}}>
                                            {listValue.offer.vehicleParts.map((part, partIndex) => (
                                                <li key={partIndex}>
                                                    {part.name} <b>{part.value}</b>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <p>No vehicle parts data available</p>
                                    )}
                                    </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
export default connect((state) => {
  return state;
}, actions)(CarSaleOffers);
// (CarMotorSave)
