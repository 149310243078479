import React, { Component } from "react";
import { GetCarsInfoContainer, ShowActiveData } from "./style";

import { connect } from "react-redux";
import { justShowSell } from "../../Actions";

import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

class GetCarsInfo extends Component {
  state = {
    carInfosHead: [
      "plaka",
      "marka",
      "model",
      "renk",
      "alış tarihi",
      "alış fiyatı",
      "masraf",
      "toplam maliyet",
      "İlandaki fiyatı",
    ],
  };
  componentDidMount() {
    this.props.justShowSell();
  }

  numberWithCommas = (x) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }

  render() {
    const { justShow } = this.props.borzMotor;
    const { carInfosHead } = this.state;
    
    return (
      <GetCarsInfoContainer>
        {/*                 <div className="showActiveHead">
                    <h1>Aktif Araçlar</h1>
                    <p>Aktif ilanlar içindeki bilgileri hızlıca gör!</p>
                </div> */}
        <div className="carContainer">
          {carInfosHead && carInfosHead.map((head) => <span>{head}</span>)}
        </div>
        <div className="carContainer carList">
          {justShow &&
            justShow.map(
              (
                {
                  plaka,
                  brand,
                  model,
                  color,
                  purchaseDate,
                  cost,
                  price,
                  sellPrice,
                },
                i
              ) => (
                <ShowActiveData key={i}>
                  <li>{plaka}</li>
                  <li>{brand}</li>
                  <li>{model}</li>
                  <li>{color}</li>
                  <li>{moment(purchaseDate).format("DD MMMM YY")}</li>
                  <li>
                    <strong>
                      {sellPrice === "0"
                        ? "Fiyat Girilmemiş"
                        : sellPrice + " TL"}
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {cost === "0" ? "Fiyat Girilmemiş" : cost + " TL"}
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {sellPrice === "0" || cost === "0"
                        ? "Fiyat Girilmemiş"
                        : (this.numberWithCommas(String((parseInt(sellPrice.replace(",","").replace(",","")) + parseInt(cost.replace(",","").replace(",","")))))) + "TL"}
                    </strong>
                  </li>
                  <li>
                    <strong>
                      {price === "0" ? "Fiyat Girilmemiş" : price + " TL"}
                    </strong>
                  </li>
                </ShowActiveData>
              )
            )}
        </div>
        <div className="justMobileList">
          {justShow &&
            justShow.map(
              (
                {
                  plaka,
                  brand,
                  model,
                  color,
                  purchaseDate,
                  cost,
                  price,
                  sellPrice,
                },
                i
              ) => (
                <ShowActiveData key={i}>
                  <li>
                    <span>Plaka: </span>
                    <span>
                      <strong>{plaka}</strong>
                    </span>
                  </li>
                  <li>
                    <span>Marka: </span>
                    <span>
                      <strong>{brand}</strong>
                    </span>
                  </li>
                  <li>
                    <span>Model: </span>
                    <span>
                      <strong>{model}</strong>
                    </span>
                  </li>
                  <li>
                    <span>Renk: </span>
                    <span>
                      <strong>{color}</strong>
                    </span>
                  </li>
                  <li>
                    <span>Alış Tarihi: </span>
                    <span>
                      <strong>
                        {moment(purchaseDate).format("DD MMMM YYYY")}
                      </strong>
                    </span>
                  </li>
                  <li>
                    <span>Alış Fiyatı: </span>
                    <span>
                      <strong>
                        {sellPrice === "0"
                          ? "Fiyat Girilmemiş"
                          : sellPrice + " TL"}
                      </strong>
                    </span>
                  </li>
                  <li>
                    <span>Masraf: </span>
                    <span>
                      <strong>
                        {cost === "0" ? "Fiyat Girilmemiş" : cost + " TL"}
                      </strong>
                    </span>
                  </li>
                  <li>
                    <span>Toplam Maliyet: </span>
                    <span>
                      <strong>
                        {sellPrice === "0" || cost === "0"
                          ? "Fiyat Girilmemiş"
                          : (this.numberWithCommas(String((parseInt(sellPrice.replace(",","").replace(",","")) + parseInt(cost.replace(",","").replace(",","")))))) + "TL"}
                      </strong>
                    </span>
                  </li>
                  <li>
                    <span>İlandaki Fiyatı: </span>
                    <span>
                      <strong>
                        {price === "0" ? "Fiyat Girilmemiş" : price + " TL"}
                      </strong>
                    </span>
                  </li>
                </ShowActiveData>
              )
            )}
        </div>
      </GetCarsInfoContainer>
    );
  }
}

export default connect(
  (state) => {
    return state;
  },
  { justShowSell }
)(GetCarsInfo);
