import React, { Component } from 'react'
import Input from '../../Components/FormElements/Input/Loadable'
import Button from '../../Components/FormElements/Button/Loadable'

import { ModelSaveContainer } from './style'

import ModelsView from '../ModelsView'

import {connect} from 'react-redux';
let actions = require('../../Actions');


class CarModelSave extends Component {
    constructor(props){
        super(props);
        this.state={
            inputValue: ""
        }
        this.inputChange = this.inputChange.bind(this)
        this.submitForm = this.submitForm.bind(this)
    }    



/*    componentDidUpdate(prevProps, prevState) {
        if(prevProps.borzMotor.model !== this.props.borzMotor.model){
            window.location.reload()
        }
    }*/


    submitForm(e){
        e.preventDefault();
        const { inputValue } = this.state;
        this.props.addCarModel(inputValue)
        this.setState({
            inputValue
        });
    }

    inputChange(e){
        let inputValue = e.target.value;
        this.setState({inputValue})
    }
    render() {
        return (
            <ModelSaveContainer>
                <form onSubmit={this.submitForm}>
                    <Input
                        onChange={ this.inputChange }
                        labelText="Model Adı"
                        type="text"
                        inputID="modelSaveCar"
                        inputHtmlFor="modelSaveCar"
                    />
                    <Button 
                        type="submit"
                        label="Model Kaydet"
                    />
                </form>
                <ModelsView/>
            </ModelSaveContainer>
        )
    }
}
export default connect((state)=>{return state},actions)(CarModelSave)
