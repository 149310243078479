import styled from 'styled-components'

const CreditViewComponent = styled.div`

  div{
    display: flex;
              span{
    display: block;
    max-width: 25%;
    padding: 5px 10px;
    width: 100%;
    margin-right: 3px;
    text-align: center;
            }
            &:first-child{
                span{
                background-color: #2f3032;
                color: #fff;
                border-radius: 5px;
                }
            }
            &:last-child{
            margin-top: 10px;
            background-color: #fff;
              border: 1px #ededed solid;
              border-top-left-radius: 0;
              border-top-right-radius: 0;
              border-bottom-left-radius: 5px;
              border-bottom-right-radius: 5px;
            }
  }

`;

export { CreditViewComponent }