import React, {Component} from 'react';
import {connect} from 'react-redux';
import { setCredit } from "../../Actions";
import CreditView from '../CreditView'
import { CreditContainer, CreditGroup, CreditFormView, CreditForm } from './style'
import Input from '../../Components/FormElements/Input'
import Button from '../../Components/FormElements/Button'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

class Credit extends Component {
    state={
        maxValue:null,
        minValue:null,
        range:null,
        yearValue:null
    }


    componentDidUpdate(prevProps, prevState) {
        const { successCredit } = this.props.borzMotor;
        if(prevProps.borzMotor.successCredit !== successCredit){
            window.location.reload()
        }
    }

    formSubmit = (e) => {
        e.preventDefault();
        const {maxValue, minValue, range, yearValue} = this.state;
        if(maxValue && minValue && range && yearValue){
            const state = this.state;
            this.props.setCredit(state)
        }else{
            return Swal.fire({
                title: 'Kaydedilemedi! Boş Alanları Doldurunuz.',
                imageUrl: 'https://unsplash.it/400/200',
                imageWidth: 400,
                imageHeight: 200
            })
        }
    }

    inputChange = (e) => {
        const target = e.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <CreditContainer>
                <CreditForm>
                    <form onSubmit={this.formSubmit}>
                        <CreditGroup>
                            <Input
                                name="maxValue"
                                placeholder="Örneğin 20000"
                                labelText="Maksimum Kredi Tutarı"
                                type="number"
                                inputHtmlFor="maxValue"
                                inputID="maxValue"
                                onChange={this.inputChange}
                            />
                        </CreditGroup>
                        <CreditGroup>
                            <Input
                                name="minValue"
                                placeholder="Örneğin 2000"
                                labelText="Minimum Kredi Tutarı"
                                type="number"
                                inputHtmlFor="minValue"
                                inputID="minValue"
                                onChange={this.inputChange}
                            />
                        </CreditGroup>
                        <CreditGroup>
                            <Input
                                name="range"
                                placeholder="Örneğin 1.75"
                                labelText="Kredi Faiz Oranı"
                                type="text"
                                inputHtmlFor="range"
                                inputID="range"
                                onChange={this.inputChange}
                            />
                        </CreditGroup>
                        <CreditGroup>
                            <Input
                                name="yearValue"
                                placeholder="Yıl giriniz, Örneğin 3"
                                labelText="Kredi Vadesi"
                                type="number"
                                inputHtmlFor="yearValue"
                                inputID="yearValue"
                                onChange={this.inputChange}
                            />
                        </CreditGroup>
                        <CreditGroup>
                            <Button label="Gönder"/>
                        </CreditGroup>
                    </form>
                </CreditForm>
                <CreditFormView>
                    <CreditView />
                </CreditFormView>
            </CreditContainer>
        );
    }
}

export default connect((state) => { return state }, { setCredit })(Credit)