import React, { Component } from "react";
import Input from "../../Components/FormElements/Input/Loadable";
import Button from "../../Components/FormElements/Button/Loadable";

import { WhoTookProcessContainer } from "./style";

import WhoTookView from "../WhoTookView";

import { connect } from "react-redux";
let actions = require("../../Actions");

class WhoTookProcess extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
    };
    this.inputChange = this.inputChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.borzMotor.whoTook !== this.props.borzMotor.whoTook) {
      window.location.reload();
    }
  }

  submitForm(e) {
    e.preventDefault();
    const { inputValue } = this.state;
    this.props.addWhoTook(inputValue);
    this.setState({
      inputValue,
    });
  }

  inputChange(e) {
    let inputValue = e.target.value;
    this.setState({ inputValue });
  }
  render() {
    return (
      <WhoTookProcessContainer>
        <form onSubmit={this.submitForm}>
          <Input
            onChange={this.inputChange}
            labelText="Aracı Alan Kişi Adı Soyadı"
            type="text"
            inputID="WhoTookProcess"
            inputHtmlFor="WhoTookProcess"
          />
          <Button type="submit" label="Kişi Kaydet" />
        </form>
        <WhoTookView />
      </WhoTookProcessContainer>
    );
  }
}
export default connect((state) => {
  return state;
}, actions)(WhoTookProcess);
