import React, { Component } from "react";

import axios from "axios";
import { development } from "../../config";

import { connect } from "react-redux";
import { Container } from "./style";
let actions = require("../../Actions");

class BonusReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentYearData: [],
      currentYear: new Date().getFullYear(),
    };
  }

  setCurrentYear(year) {
    let currentYearData = [];
    this.state.data.map((x) => {
      if (new Date(x.date).getFullYear() == year) {
        currentYearData.push(x);
      }
    });
    this.setState({
      currentYear: year,
      currentYearData: currentYearData,
    });
    console.log("this.state.currentYearData");
    console.log(this.state.currentYearData);
  }

  componentDidMount() {
    let url = `${development}/api/allVehicleViews`;
    axios.get(url).then(
      (res) => {
        if (res && res.data && res.data.length > 0) {
          let data = res.data.map((x) => {
            if (x.bonusData && x.bonusData.length > 0) {
              x.bonusData = JSON.parse(x.bonusData);
            }
            return x;
          });
          this.setState({
            data: data,
          });
          this.setCurrentYear(this.state.currentYear);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  render() {
    return (
      <Container>
        <div className="yearSection">
          <div style={{ margin: 15 }}>
            <button
              onClick={() => this.setCurrentYear(new Date().getFullYear())}
              className={
                this.state.currentYear === new Date().getFullYear()
                  ? "active"
                  : ""
              }
              style={{
                padding: 15,
                borderRadius: 5,
                fontSize: 16,
              }}
            >
              {new Date().getFullYear()}
            </button>
          </div>
          <div style={{ marginLeft: 15, padding: 20 }}>
            {Array.from({ length: 5 }).map((_, index) => (
              <button
                onClick={() =>
                  this.setCurrentYear(new Date().getFullYear() - (index + 1))
                }
                className={
                  this.state.currentYear ===
                  new Date().getFullYear() - (index + 1)
                    ? "active"
                    : ""
                }
                key={index}
                style={{
                  padding: 10,
                  marginRight: 5,
                  borderRadius: 5,
                  fontSize: 16,
                }}
              >
                {new Date().getFullYear() - (index + 1)}
              </button>
            ))}
          </div>
        </div>
        <div className="dataList">
          {Array.from({ length: 12 }).map((_, index) => (
            <div>
              <div className="monthPart" key={index}>
                {new Date(2000, index).toLocaleDateString("tr-TR", {
                  month: "long",
                })}{" "}
                {this.state.currentYear}
              </div>
              <div className="monthDataList">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Plaka</th>
                      <th>Prim</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.currentYearData
                      .filter(
                        (x) =>
                          new Date(x.date).getMonth() === index &&
                          x.bonusData &&
                          x.bonusData.length > 0
                      )
                      .map((y, i) => (
                        <tr key={y.id}>
                          <td>{i + 1}</td>
                          <td>{y.plaka}</td>
                          <td>
                            <table>
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>Prim Adı</th>
                                  <th>Hak Eden</th>
                                  <th>Toplam Tutar</th>
                                </tr>
                              </thead>
                              <tbody>
                                {y.bonusData.map((z, b) => (
                                  <tr key={z.bonusName + b + y.plaka}>
                                    <td>{b + 1}</td>
                                    <td>{z.bonusName}</td>
                                    <td>{z.deservingName}</td>
                                    <td>{z.amount} TL</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      </Container>
    );
  }
}

export default connect((state) => {
  return state;
}, actions)(BonusReport);
