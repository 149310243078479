import React, { Component } from 'react'
import {Helmet} from 'react-helmet'
import { EmployeeViewContainer } from './style'

import ModelViewComponent from '../../Components/ModelView/Loadable'
import Input from '../../Components/FormElements/Input/Loadable'

import {connect} from 'react-redux';
let actions = require('../../Actions');

class EmployeeView extends Component {
    state={
        employeeNameState: [],
        employeeListState: []
    }
    componentDidMount() {
        this.props.employeeView();
    }

    filterBrands(e){
        const { employeeView } = this.props.borzMotor;
        const findTo = e.target.value;
        findTo.length === 0 && this.setState({ employeeNameState: [] })
        employeeView && employeeView.filter(emp =>  emp.employee === findTo && this.setState({employeeNameState: [emp]}))
    }

    render() {
        const { employeeView } = this.props.borzMotor;
        const { employeeNameState } = this.state;
        return (
            <EmployeeViewContainer>
                <Helmet>
                    <title>Aracı Bulan Kişiler | Borz Motor</title>
                </Helmet>
                <div className="addToModelsViewLabel">
                    <span>Kayıtlı olan kişiler</span>
                </div>
                <div>
                    <Input
                        type="text"
                        labelText="Kişiler içinde ara"
                        inputID="employeeView"
                        inputHtmlFor="employeeView"
                        onChange={ (e) => this.filterBrands(e) }
                    />
                </div>
                <div className="allModelsContainer">
                    {
                        employeeNameState && employeeNameState.length > 0 ?
                            employeeNameState && employeeNameState.map(( {_id, employee} ) => (
                                employee && <ModelViewComponent key={_id}>
                                    {employee}
                                </ModelViewComponent>
                            ))
                            :
                            employeeView && employeeView.length > 0 ?
                                employeeView && employeeView.map(( {_id, employee} ) => (
                                    employee && <ModelViewComponent key={_id}>
                                        {employee}
                                    </ModelViewComponent>
                                ))
                                : <div>Kayıtlı Kişi Bulunamadı</div>
                    }
                </div>

            </EmployeeViewContainer>
        )
    }
}

export default connect((state)=>{return state},actions)(EmployeeView)
