import styled from 'styled-components'

const ButtonContainer = styled.div `
margin-top: 10px;
text-align: center;
    button{
        width: 100%;
        max-width:300px;
        border:none;
        background-color: #89abf7; 
        color: #fff;
        padding:10px;
        border-radius:4px;
        cursor: pointer;
        text-transform:capitalize;
            &:hover{
                opacity: .7
            }
            &:active{
                color: #5e5f80
            }
    }
`;

export { ButtonContainer };

