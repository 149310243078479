const INITIAL_STATE = {
  model: "",
  modelName: [],
  brand: {},
  box: {},
  motor: {},
  employee: {},
  employeeView: [],
  whoTook: {},
  whoTookView: [],
  whoSold: {},
  whoSoldView: [],
  brandList: [],
  boxList: [],
  motorList: [],
  updateCarId: {},
  carDelete: "",
  carDeleteSuccess: null,
  carDeleteData: {},
  login: {},
  aboutParam: {},
  getAboutParam: {},
  allCarList: [],
  active: [],
  deActive: [],
  carSavePending: false,
  carSaveError: null,
  credit: {},
  successCredit: null,
  getCredit: {},
  justShow: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "CARSAVEPENDING": {
      return { ...state, carSavePending: true };
    }
    case "ADDCARMODEL": {
      return { ...state, model: action.model };
    }
    case "VIEWCARMODEL": {
      return { ...state, modelName: action.modelName };
    }
    case "GETABOUT": {
      return { ...state, getAboutParam: action.getAboutParam };
    }
    case "ADDBRAND": {
      return { ...state, brand: action.brand };
    }
    case "ACTIVEVEHICLE": {
      return { ...state, active: action.active, deActive: [] };
    }
    case "DEACTIVEVEHICLE": {
      return { ...state, active: [], deActive: action.deActive };
    }
    case "ADDBOX": {
      return { ...state, box: action.box };
    }
    case "CREDITSUCCESS": {
      return { ...state, successCredit: action.successCredit };
    }
    case "ADDMOTOR": {
      return { ...state, motor: action.motor };
    }
    case "LOGIN": {
      return { ...state, login: action.login };
    }
    case "DELETECAR": {
      return {
        ...state,
        carDelete: action.carDelete,
        carDeleteSuccess: action.carDeleteSuccess,
        carDeleteData: action.carDeleteData,
        allCarList: state.allCarList.filter(
          (del) => del._id !== action.carDeleteData._id
        ),
      };
    }
    case "CARUPDATEGETBYID": {
      return { ...state, updateCarId: action.updateCarId };
    }
    case "VIEWCARBRAND": {
      return { ...state, brandList: action.brandList };
    }
    case "VIEWEMPLOYEE": {
      return { ...state, employeeView: action.employeeView };
    }
    case "VIEWWHOTOOK": {
      return { ...state, whoTookView: action.whoTookView };
    }
    case "VIEWWHOSOLD": {
      return { ...state, whoSoldView: action.whoSoldView };
    }
    case "VIEWCARBOX": {
      return { ...state, boxList: action.boxList };
    }
    case "VIEWCARMOTOR": {
      return { ...state, motorList: action.motorList };
    }
    case "JUSTSHOW": {
      return { ...state, justShow: action.justShow };
    }
    case "RESERVATIONLIST": {
      return { ...state, reservationList: action.reservationList }
    }
    case "ADDEMPLOYEE": {
      return { ...state, employee: action.employee };
    }
    case "ADDWHOTOOK": {
      return { ...state, whoTook: action.whoTook };
    }
    case "ADDWHOSOLD": {
      return { ...state, whoSold: action.whoSold };
    }
    case "CARSAVEERROR": {
      return {
        ...state,
        carSavePending: false,
        carSaveError: action.carSaveError,
      };
    }
    case "VIEWALLCAR": {
      return { ...state, allCarList: action.allCarList };
    }
    case "CREDIT": {
      return { ...state, credit: action.credit };
    }
    case "GETCREDIT": {
      return {
        ...state,
        getCredit: action.getCredit,
      };
    }
    case "ABOUT": {
      return { ...state, aboutParam: action.aboutParam };
    }    
    default:
      return { ...state };
  }
};
